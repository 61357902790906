module.exports = {
  pathPrefix: '/',
  title: {
    "fr": `David Jarre: magicien, illusionniste et mentaliste professionnel`,
    "en": `David Jarre: professional magician, illusionist and mentalist.`
  },
  description: {
    "fr": `David Jarre, magicien, mentaliste et illusionniste spécialisé en close-up et en mentalisme pour soirées privées haut de gamme, mariages ou événements corporate.`,
    "en": `David Jarre, professional magician, illusionist, mentalist specialised in close up magic and mentalisme for high end private parties, weddings and corporate events.`
  },
  url: 'https://www.davidjarre.com',
  logo: 'static/logo.png',
  ogLanguage: 'fr',
  favicon: 'static/favicon-david.png',
  shortName: 'davidjarre',
  author: 'David Jarre',
  themeColor: '#000000',
  backgroundColor: '#FFFFFF',
  googleAnalyticsID: 'UA-73602642-1',
  facebook: 'https://www.facebook.com/profile.php?id=100008265047250&fref=ts',
  instagram: 'https://www.instagram.com/david_jarre/?hl=fr'
}
