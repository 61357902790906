module.exports = [
    {
        id: '/',
        lang: 'fr',
        slug: '/',
        alternates: {
            fr: '/',
            en: '/en/',
        },
        title: `David Jarre: magicien, illusionniste et mentaliste professionnel`,
        description: `David Jarre, magicien professionnel spécialisé en magie virtuelle. Shows en ligne uniques pour soirées privées haut de gamme, anniversaires ou événements corporate.`,
        content: [
            [
                `David Jarre est un artiste magicien franco-anglais, spécialisé dans la magie de proximité, dite close up, le mentalisme et la magie de scène.`,
            ],
            [
                `Il est également connu pour ses apparitions à la télévision française, dans l'émission "Vivement Dimanche" animée par Michel Drucker.`
            ]
        ]
    },

    {
        id: '/',
        lang: 'en',
        slug: '/en',
        alternates: {
            fr: '/',
            en: '/en/',
        },
        title: `David Jarre: magicien, illusionniste et mentaliste professionnel`,
        description: `David Jarre, magicien professionnel spécialisé en magie virtuelle. Shows en ligne uniques pour soirées privées haut de gamme, anniversaires ou événements corporate.`,
        content: [
            [
                `David Jarre is a French-English magician specializing in close-up magic, mentalism and on-stage magic.`,
            ],
            [
                `He is also known for his appearances on French television, on the show "Vivement Dimanche" hosted by Michel Drucker.`
            ]
        ]
    },

    {
        id: 'a-propos',
        lang: 'fr',
        slug: '/a-propos',
        alternates: {
            fr: '/a-propos/',
            en: '/en/about/',
        },
        title: `David Jarre: magicien, illusionniste et mentaliste professionnel`,
        description: `David Jarre, magicien professionnel spécialisé en magie virtuelle. Shows en ligne uniques pour soirées privées haut de gamme, anniversaires ou événements corporate.`,
        content: [
            [
                `David Jarre est un artiste magicien franco-anglais spécialisé dans la magie de proximité, la magie de scène et le mentalisme.`,
                `La magie de proximité, dite close up, est un style de magie qui se déroule à courte distance du public. Une expérience interactive et immersive pour les spectateurs.`,
            ],
            [
                [
                    `David Jarre est magicien professionnel depuis qu'il a 20 ans. Des milliers de performances à travers le monde lui ont permis de bâtir sa réputation comme l'un des grands magiciens d'aujourd'hui.`,
                    `Dans les rues ou dans les palais, pour des anonymes ou des célébrités, David a perfectionné sa technique et maîtrisé l'art de capter tout public et d'improviser en tout contexte.`,
                    `Homme de son temps, David a une manière irrésistible de vous prendre la main avec audace, mêlant sa personnalité à toute la ruse de sa magie. Il fait partie de ce cercle fermé de talents singuliers, imprévisibles, qu'on ne peut classer ou mettre dans une case.`,
                ],
                [
                    `David a commencé la magie à 10ans. Le déclencheur de sa passion a été de voir le magicien polonais Michael Konieczny, connu sous le nom Vadini.`,
                    `"J'étais fasciné par l'atmosphère qu'il arrivait à créer et comment il réussissait à capter l'attention de tout le monde. Avec sa magie, il avait une façon poétique de nous faire croire qu'une autre forme de réalité était possible."`,
                    `"La magie remet en question notre notion de logique, elle vient taquiner le modèle de nos croyances, de la même manière qu'un dessin de Eischer s'amuse à travestir notre perception de la réalité."`
                ]
            ]
        ]
    },

    {
        id: 'a-propos',
        lang: 'en',
        slug: '/en/about',
        alternates: {
            fr: '/a-propos/',
            en: '/en/about/',
        },
        title: `David Jarre: magicien, illusionniste et mentaliste professionnel`,
        description: `David Jarre, magicien professionnel spécialisé en magie virtuelle. Shows en ligne uniques pour soirées privées haut de gamme, anniversaires ou événements corporate.`,
        content: [
            [
                `David Jarre is a Franco-British illusionist specialized in close-up, stage magic, and mentalism.`,
                `Close-up magic is a style of magic that takes place at a short distance from the audience. It offers an interactive and immersive experience for spectators.`,
            ],
            [
                [
                    `David Jarre has been a professionnal magician since he was 20. Thousands of performances have taken him around the world and have helped him build his reputation as one of the great magicians of today.`,
                    `On the streets or in palaces, for anonymous crowds or celebrities, David has perfected his technique and mastered the art of captivating any audience and improvising in any context.`,
                    `A man of his time, half-gentleman half-rogue, David has an irresistible way of taking your hand with audacity, blending his personality with the artfullness of his magic. David Jarre is part of that very select circle of unpredictable, singular talents, those who cannot be labelled or made to fit into any given shape.`,
                ],
                [
                    `"What I love about magic is that it challenges our notion of logic, it teases the template of our beliefs, in the same way that a drawing of Eischer might play with our perception of reality."`,
                    `David started magic when he was 10. The trigger to his passion was seeing perform Polish magician Michael Konieczny, known as Vadini.`,
                    `"I was fascinated by the atmosphere he was able to create and how he managed to capture everyone's attention. With his magic, he had a poetic way of making us believe that another form of reality was possible."`,
                ]
            ]
        ]
    },

    {
        id: 'evenements',
        lang: 'fr',
        slug: '/evenements',
        alternates: {
            fr: '/evenements/',
            en: '/en/events/',
        },
        title: `David Jarre: magicien, illusionniste et mentaliste professionnel`,
        description: `David Jarre, magicien professionnel spécialisé en magie virtuelle. Shows en ligne uniques pour soirées privées haut de gamme, anniversaires ou événements corporate.`,
        content: [
            [
                `<p>David est un professionnel reconnu depuis 20 ans. Il a une expérience du public, de la scène et du close up que seule une poignée d'artistes en Europe maîtrise à ce niveau.</p>`,
                `<p>Magicien et mentaliste, il s’est spécialisé dans les soirées privées haut de gamme dans la mode le luxe et les grands événements corporates.</p>`,
                `<p>Au cours des dernières années David à travaillé notamment pour la famille Royale de Grande-Bretagne, Bill Gates, Eric Schmitt président de Google, les Oscars à Los Angeles, à Davos au Forum Economique Mondial et il a aussi été appelé pour de nombreux événements privés pour des célébrités telles que Roger Federer, Madonna, Sting, Elton John, Adèle, Lady Gaga et bien d’autres.</p>`,
                `<p>Ces clients dans le monde du luxe incluent Dior, Chanel, Bulgari, Louis Vuitton, Porsche, Bentley, Vanity Fair, PWC, Cap Gemini et beaucoup d’autres.</p>`,
                `<p>En 2020, David s'est rapidement tourné vers cette nouvelle forme d'expression qu’est la magie virtuelle et a déjà des centaines de spectacles virtuels à son actif. Pour David et son équipe chaque événement est unique en soit et ils donneront leur maximum pour que le votre soit mémorable.</p>`,
                `<p>Depuis 2022, David est notamment reconnu pour ses apparitions de manière récurente à la télévision française, notamment dans l'émission "Vivement Dimanche" animée par Michel Drucker et C à VOUS sur France 5.</p>`,
                `<p>Pour David et son équipe chaque événement est unique en soit et ils donneront leur maximum pour que le votre soit mémorable.</p>`,
            ],
            [
                `<h2>A quoi s’attendre ?</h2>`,
                `<p>La surprise, l’étonnement, l’emerveillement.</p>`,
                `<p>C’est un moment interactif qui mélange à la fois le close-up, (magie de proximité) le mentalisme, la magie digitale, avec des objets empruntés, des bagues des montres, des téléphones, des cartes . C’est de la magie interactive.</p>`,
                `<p>Les gens se positionnent naturellement autour de David, debout, ou autour d’une table assis, quelque soit l’environnement les invités peuvent se laisser porter par la magie et l’humour de David.</p>`,
                `<p>C’est un spectacle parlé, plutôt orienté pour les adultes mais convient également aux enfants.</p>`,
                `<p>Le spectacle se déroule chez vous ou dans un lieu prévu à cet effet, sur scèène et/ou au milieu des invités.</p>`,
                `<p>De Une à mille personnes , quelque soit le nombre d’inviteés et de convives David adaptera son show pour que la magie opère.</p>`,
                `<p>Si vous avez des questions spécifiques pour engager, envoyez nous vos question et nous ferons de notre mieux pour vous fournir des informations supplémentaires en 24h.</p>`,
            ],
            [
                `<h2>Pour quelle occasion ?</h2>`,
                `<p>Toute occasion de célébration, intimiste ou avec un grand nombre d’invités. Évenements corporate et évenements privés, anniversaires, arriages...</p>`,
                `<p>C’est un spectacle parlé, plutôt orienté pour les adultes mais convient également aux enfants.</p>`,
            ],
            [
                `<h2>Combien de temps dure la performance ?</h2>`,
                `<p>La présence de David lors d’une soirée complète de close up est approximativement 3h.</p>`,
                `<p>Chaque événement est unique est à la carte donc contactez nous pour en parler plus en détails. Nous arriverons à trouver la solution qui convient le mieux pour vous et qui correspond à vos envies.</p>`,
            ],
            [
                `<h2>Combien ça coûte ?</h2>`,
                `<p>Chaque événement est unique et particulier.</p>`,
                `<p>Les coûts varient essentiellement avec le nombre d’invités, si l’intervention est en close up ou s’il s’agit d’une intervention sur scène.</p>`,
                `<p>Contactez nous et nous serons heureux d’établir un devis selon votre demande et vos envies.</p>`,
            ]
        ]
      },
      
      {
        id: 'evenements',
        lang: 'en',
        slug: '/en/events',
        alternates: {
            fr: '/evenements/',
            en: '/en/events/',
        },
        title: `David Jarre: magicien, illusionniste et mentaliste professionnel`,
        description: `David Jarre, magicien professionnel spécialisé en magie virtuelle. Shows en ligne uniques pour soirées privées haut de gamme, anniversaires ou événements corporate.`,
        content: [
            [
                `<p>David is a professional illusionist with over 20 years of experience. He has a level of experience on stage, and close-up magic that only a handful of artists in Europe master.</p>`,
                `<p>As a magician and mentalist, he specializes in high-end private parties in the fashion, luxury, and corporate events sectors.</p>`,
                `<p>Over the past years, David has notably worked for the Royal Family of Great Britain, Bill Gates, Eric Schmitt (President of Google), the Oscars in Los Angeles, Davos at the World Economic Forum, and has also been called upon for numerous private events for celebrities such as Roger Federer, Madonna, Sting, Elton John, Adele, Lady Gaga, and many others.</p>`,
                `<p>His luxury clientele includes Dior, Chanel, Bulgari, Louis Vuitton, Porsche, Bentley, Vanity Fair, PWC, Cap Gemini, and many others.</p>`,
                `<p>In 2020, David transitioned to this new form of expression known as virtual magic and has already performed hundreds of virtual shows. For David and his team, each event is unique, and they will do their utmost to make yours memorable.</p>`,
                `<p>Since 2022, David has been recognized for his recurring appearances on French television, notably on the show "Vivement Dimanche" hosted by Michel Drucker and "C à VOUS" on France 5.</p>`,
                `<p>For David and his team, each event is unique, and they will do their utmost to make yours memorable.</p>`,
            ],
            [
                `<h2>What to expect?</h2>`,
                `<p>Surprise, astonishment, wonder.</p>`,
                `<p>It's an interactive moment that combines close-up magic, mentalism, digital magic, with borrowed objects such as rings, watches, phones, and cards.</p>`,
                `<p>People naturally gather around David, standing or sitting around a table, whatever the environment, guests will be captivated by David's magic and humor.</p>`,
                `<p>It's a spoken show, more oriented towards adults but also suitable for children.</p>`,
                `<p>The show takes place at your location or at a designated venue, on stage and/or among the guests.</p>`,
                `<p>From one to a thousand people, regardless of the number of guests and attendees, David will adapt his show and allow the magic happen.</p>`,
                `<p>If you have specific questions before booking, send us your questions, and we will do our best to provide you with additional information within 24 hours.</p>`,
            ],
            [
                `<h2>For what occasion?</h2>`,
                `<p>Any celebratory occasion, whether intimate or with a large number of guests. Corporate events and private events, birthdays, weddings...</p>`,
                `<p>It's a spoken show, more oriented towards adults but also suitable for children.</p>`,
            ],
            [
                `<h2>How long does the performance last?</h2>`,
                `<p>David's presence during a full evening of close-up magic is approximately 3 hours.</p>`,
                `<p>Each event is unique and customizable, so contact us to discuss it further. We will find the best solution for you that matches your desires.</p>`,
            ],
            [
                `<h2>How much does it cost?</h2>`,
                `<p>Each event is unique and specific.</p>`,
                `<p>Costs mainly vary with the number of guests, whether it's close-up intervention or a stage performance.</p>`,
                `<p>Contact us, and we will be happy to provide a quote based on your request and desires.</p>`,
            ]
        ]
      },
  ]
  