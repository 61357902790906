module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-73602642-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"David Jarre: professional magician, illusionist and mentalist.","short_name":"davidjarre","description":"David Jarre, professional magician, illusionist, mentalist specialised in close up magic and mentalisme for high end private parties, weddings and corporate events.","start_url":"/","background_color":"#FFFFFF","theme_color":"#000000","display":"standalone","icon":"static/favicon-david.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cb5694e465e1c7006982c6a5151f9de8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
