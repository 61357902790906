const theme = {
  colors: {
    background: '#ffffff',
    text: '#000000'
  },
  fontFamily: "'Poppins', sans-serif",
  maxWidth: '1170px',
  breakpoints: {
    xxs: '320px',
    xs: '420px',
    xsLandscape: '668px',
    s: '1050px',
    m: '1248px',
    l: '1366px',
  },
}

export default theme
