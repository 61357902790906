const website = require('./website')
const pages = require('./pages')

module.exports = {
    'fr': {
        default: true,
        path: 'fr',
        locale: 'fr',
        ogLanguage: 'fr',
        defaultTitle: website.title.fr,
        defaultDescription: website.description.fr,
        notifications: [
            `Votre message a bien été envoyé`,
            `Envoi en cours...`
        ],
        buttons: [
            `Contacter David`,
            `Engagez David pour votre événement virtuel`,
            `Envoyer`,
            `Parlez nous de votre événement`,
            `Contact`,
        ],
        labels: [
            `Votre nom`,
            `Objet du message`,
            `Adresse email`,
            `Votre evenement nous interesse...`,
            `À propos`,
            `Événements`,
            `Accueil`,
        ],
        pages: pages.filter((item) => item.lang === 'fr'),
    },

    'en': {
        default: false,
        path: 'en',
        locale: 'en',
        ogLanguage: 'en',
        defaultTitle: website.title.en,
        defaultDescription: website.description.en,
        notifications: [
            `Your message has been sent`,
            `Sending...`
        ],
        buttons: [
            `Contact us`,
            `Book David for your online event`,
            `Send`,
            `Tell us about your event`,
            `Contact`,
        ],
        labels: [
            `Name`,
            `Subject`,
            `Email address`,
            `Tell us about your event...`,
            `About`,
            `Events`,
            `Home`,
        ],
        pages: pages.filter((item) => item.lang === 'en'),
    },
}
